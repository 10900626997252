
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import BigCard from '@/ui-components/BigCard/BigCard.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import CampaignStatus from '@/ui-components/CampaignStatus/CampaignStatus.vue';
import { StoreCampaign } from '@/types/campaigns';
import { Header } from '@/types/misc';
import Truncated from '@/shared-components/Truncated.vue';
import { isNumber } from 'lodash';

@Component({
  name: 'TableRow',
  components: {
    Truncated,
    BigCard,
    CampaignStatus,
    IButton,
  },
})
export default class TableRow extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  public campaign!: StoreCampaign;

  @Prop({ type: Array, default: () => [] })
  public headers!: Header[];

  isNumber(value: number | string) {
    return isNumber(value);
  }

  @Emit('rowClick')
  public rowClick(campaign: StoreCampaign) {
    return campaign;
  }
}
