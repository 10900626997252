
import { Component, Prop, Vue } from 'vue-property-decorator';
import IButton from '@/ui-components/IButton/IButton.vue';

@Component({
  name: 'Pagination',
  components: {
    IButton,
  },
})
export default class Pagination extends Vue {
  @Prop({ type: Number, default: 5 })
  public itemsPerPage!: number;

  @Prop({ type: Number, default: 1 })
  public selectedPage!: number;

  @Prop({ type: Number, default: 0 })
  public totalCount!: number;

  public itemsPerPageChanged(items: number) {
    this.itemsPerPage = items;
  }

  public goToPage(page: number) {
    if (page < 1 || page > this.totalPagesCount) {
      return;
    }
    this.selectedPageNumber = page;
  }

  public get totalPagesCount() {
    return Math.ceil(this.totalCount / this.itemsPerPage);
  }

  public get showMinimumSeparator() {
    return !this.displayedPaginationPages.includes(2);
  }

  public get showMaximumSeparator() {
    return !this.displayedPaginationPages.includes(this.totalPagesCount - 1);
  }

  public get displayedPaginationPages() {
    if (this.selectedPage === 3, this.totalPagesCount === 5) {
      return [2, 3, 4]
    }

    const possiblePages = [
      this.selectedPage - 2,
      this.selectedPage - 1,
      this.selectedPage,
      this.selectedPage + 1,
      this.selectedPage + 2,
    ];

    const filteredPossibilities = possiblePages.filter(
      (item) => item > 1 && item < this.totalPagesCount
    );
    
    const minimumPage = 1 - (this.selectedPage <= 3 ? 1 : 0)
    const maximumPage = minimumPage + (this.selectedPage <= 3 ? 3 : 2);

    return filteredPossibilities.length > 2
      ? filteredPossibilities.splice(minimumPage, maximumPage)
      : filteredPossibilities;
  }

  public get firstItemCount() {
    return (
      (this.selectedPage ? this.selectedPage - 1 : this.selectedPage) *
        this.itemsPerPage +
      1
    );
  }

  public get lastItemCount() {
    const expectedLastCount = this.firstItemCount + this.itemsPerPage - 1;
    return Math.min(expectedLastCount, this.totalCount);
  }

  public get paginationRange() {
    return `${this.firstItemCount} - ${this.lastItemCount} ${this.$t('_of')} ${
      this.totalCount
    } ${this.$t('_items')}`;
  }

  public get selectedPageNumber() {
    return this.selectedPage
  }

  public set selectedPageNumber(val: number) {
    this.$emit('update:selectedPage', val)
  }
}
