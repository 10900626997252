
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Truncated extends Vue {
  @Prop({ required: true, type: String }) text!: string;
  @Prop({ required: true, type: Number }) maxLength!: number;

  public show = false;
}
