
import { Component, Prop, Vue } from 'vue-property-decorator';
import BigCard from '@/ui-components/BigCard/BigCard.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import CampaignStatus from '@/ui-components/CampaignStatus/CampaignStatus.vue';

@Component({
  name: 'ItemsRange',
  components: {
    BigCard,
    CampaignStatus,
    IButton,
  },
})
export default class ItemsRange extends Vue {
  @Prop({ type: Number, default: 10 })
  public totalCount!: number;

  @Prop({ type: Number, default: 5 })
  public itemsPerPage!: number;

  @Prop({ type: Number, default: 1 })
  public selectedPage!: number;

  public get firstItemCount() {
    return (
      (this.selectedPage ? this.selectedPage - 1 : this.selectedPage) *
        this.itemsPerPage + 1
    );
  }

  public get lastItemCount() {
    const expectedLastCount = this.firstItemCount + this.itemsPerPage - 1;
    return Math.min(expectedLastCount, this.totalCount);
  }

  public get paginationRange() {
    return `${this.firstItemCount} - ${this.lastItemCount} ${this.$t('_of')} ${
      this.totalCount
    } ${this.$t('_items')}`;
  }
}
